import React from "react"
import { Text } from "../../../platform-impl/components/common/Text"
import { LoadingIcon } from "../../../platform-impl/components/common/LoadingIcon"

export const FullScreenLoading = (): JSX.Element => {
  return (
    <div className="h-100 flex flex-col items-center justify-center">
      <LoadingIcon />
      <Text bold>Loading</Text>
    </div>
  )
}
