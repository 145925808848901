import { Category, Contract, DbObject, Project } from "../models/model"
import { StateCreator } from "zustand"
import { StoreState } from "./store"
import { resetActions } from "./resetStore"
import deepEqual from "fast-deep-equal"
import { existsSync } from "fs"
import { extractCategory, extractContract, extractProject } from "../models/extractFns"

export interface ModelSlice {
  categories: Record<string, Category>
  projects: Record<string, Project>
  contracts: Record<string, Contract>
}

const initialState: ModelSlice = {
  categories: {},
  projects: {},
  contracts: {},
}

export const createModelSlice: StateCreator<StoreState, [], [], ModelSlice> = set => {
  resetActions.push(() => set(initialState))
  return initialState
}

const upsert = <T extends DbObject>(
  existing: Record<string, T>,
  newItems: T[] | undefined,
  extractFn: (item: T) => T
): void => {
  for (const item of newItems ?? []) {
    const extracted = extractFn(item)
    if (!deepEqual(existing[item._id], extracted)) {
      existing[item._id] = extracted
    }
  }
}

const upsertPartial = <T extends DbObject>(
  existing: Record<string, T>,
  newItems: Partial<T>[] | undefined,
  extractFn: (item: T) => T
): void => {
  const upserts: T[] = []
  for (const item of newItems ?? []) {
    if (item._id == null) continue
    if (!(item._id in existing)) continue
    const old = existing[item._id]
    if (old == null) continue
    upserts.push({ ...old, ...item })
  }
  upsert(existing, upserts, extractFn)
}

export const remove = <T extends DbObject>(existing: Record<number, T>, id: number): void => {
  delete existing[id]
}

export const upsertCategories = (state: StoreState, categories: Category[] | undefined): void =>
  upsert(state.categories, categories, extractCategory)

export const upsertCategoriesPartial = (state: StoreState, categories: Partial<Category>[] | undefined): void =>
  upsertPartial(state.categories, categories, extractCategory)

export const upsertProjects = (state: StoreState, projects: Project[] | undefined): void =>
  upsert(state.projects, projects, extractProject)

export const upsertContracts = (state: StoreState, contracts: Contract[] | undefined): void =>
  upsert(state.contracts, contracts, extractContract)

export const upsertProjectsPartial = (state: StoreState, categories: Partial<Category>[] | undefined): void =>
  upsertPartial(state.projects, categories, extractProject)
