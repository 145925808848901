import React from "react"
import { App } from "./platform-impl/components/app/App"
import { reportWebVitals } from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { CookiesProvider } from "react-cookie"

import { createRoot } from "react-dom/client"

const root = createRoot(document.getElementById("root")!)
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
