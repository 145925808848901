import { api } from "../api/apiController"
import { mapIds } from "../utils/jsUtils"
import { mergeFeeds, updateFeedMapData } from "./loadedSlice"
import { upsertCategories, upsertContracts, upsertProjects } from "./modelSlice"
import { setStateImmutable } from "./store"
import { GOOGLE_SHEET_API_KEY } from "../constants/apikeys"

export const fetchCategories = async (): Promise<void> => {
  const response = await api.getCategories()
  setStateImmutable(state => {
    upsertCategories(state, response.data)
    state.feeds.categories = mergeFeeds(state.feeds.categories, { items: mapIds(response.data) }, () => 1)
  })
}

export const fetchProjects = async (): Promise<void> => {
  const response = await api.getProjects()
  setStateImmutable(state => {
    upsertProjects(state, response.data)
    state.feeds.projects = mergeFeeds(state.feeds.projects, { items: mapIds(response.data) }, () => 1)
  })
}

export const fetchContracts = async (projectId: string): Promise<void> => {
  const response = await api.getContracts(projectId)

  console.log("response from fetchContracts", response)
  setStateImmutable(state => {
    const contracts = response.data.contract_details
    console.log("look at contracts", contracts)
    if (contracts != null) {
      upsertContracts(state, contracts)
    }
    if (response.data.project_data != null) {
      upsertProjects(state, [response.data.project_data])
    }
    updateFeedMapData(state.feeds.contracts, projectId, { items: mapIds(contracts) }, () => 1)
    // state.feeds.contracts = mergeFeeds(state.feeds.contracts, { items: mapIds(response.data) }, () => 1)
  })
}

export const fetchGoogleSheetData = async (): Promise<void> => {
  fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/11t3i0djlT0KjqAr_TP6RqBv1bw7A-MehN3YMVDmMAZo/values/Sheet1?valueRenderOption=FORMATTED_VALUE&key=${GOOGLE_SHEET_API_KEY}`
  )
    .then(async res => res.json())
    .then(res => {
      console.log("now look at google sheet data", res)
    })
    .catch(e => {
      console.log("Error in fetching google sheet data", e)
    })
}
