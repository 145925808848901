import React, { FC, useState } from "react"
import { Column } from "../../platform-impl/components/common/Column"
import { Text } from "../../platform-impl/components/common/Text"
import { Input } from "../../platform-impl/components/common/Input"
import { DT_GREEN } from "../constants/colors"
import { ConsArt } from "../../platform-impl/IconPaths"
import { Button } from "../../platform-impl/components/common/Button"
import { CategoryTile } from "../components/feed/CategoryTile"
import { fetchCategories } from "../store/fetchActions"
import { useFetchData } from "../hooks/useFetchData"
import { useGetCategoryIds } from "../store/selectors"
import { MEDIUM_SCREEN_WIDTH } from "../../platform-impl/utils/appConstants"
import styled from "styled-components"
import { ErrorView } from "../components/common/ErrorView"
import { FullScreenLoading } from "../components/common/FullScreenLoading"

const LandingBoldTextDiv = styled.div`
  font-size: 55px;
  font-weight: bold;
  line-height: 1.25;
  margin-bottom: 3rem;
  margin-top: 6rem;

  @media only screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
    font-size: 36px;
    text-align: center;

    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`

const LandingSmallTextDiv = styled.div`
  font-size: 20px;

  @media only screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
    font-size: 16px;
    text-align: center;
  }
`

const LandingProjectCategoriesTitleDiv = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 1rem;
  @media only screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
    font-size: 24px;
    text-align: center;
  }
`

const CategoryWrapperDiv = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.25rem;

  @media only screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const LandingPageScreen = (): JSX.Element => {
  const [email, setEmail] = useState("")

  return (
    <>
      <div>
        <div className="flex flex-col bg-[#fff]">
          <div className="flex flex-wrap w-full justify-between">
            <div className="flex flex-col w-full md:w-5/12">
              {/* <div className="flex flex-col"> */}
              <LandingBoldTextDiv>Your One-Stop Shop For Construction Contracts!</LandingBoldTextDiv>
              {/* <div className="mb-24">
                <span className="text-[28px] lg:text-[55px] font-bold leading-tight text-center lg:text-left"></span>
              </div> */}

              <LandingSmallTextDiv>
                <div>Get Started today with a Dihadi account!</div>
                <div>Oh, did we mention it's free</div>
              </LandingSmallTextDiv>
              {/* <div>
                <span className="text-[20px] text-center lg:text-left"></span>
              </div> */}

              <div>
                <span className="text-[20px] text-center lg:text-left"></span>
              </div>
              <Text size={"20px"} marg={[0, 0, "double", 0]}></Text>
              <div className="flex items-stretch justify-between border-[#2AFD98] border-solid border-2 rounded-xl mb-4">
                <Input
                  maxLines={1}
                  value={email}
                  onValueUpdate={setEmail}
                  placeholder={"Enter your e-mail"}
                  pad={"def"}
                  borderColor={DT_GREEN}
                  color={DT_GREEN}
                  width={"80%"}
                  activeColor="#F9FBFF"
                  backgroundColor="#F9FBFF"
                />
                <Button title="Submit" type="primary" />
              </div>
              {/* </div> */}
            </div>
            <div className="w-full hidden md:block md:w-5/12 -mt-24">
              <Column>
                <img src={ConsArt.default.threeX} alt={"Construction site"} />
              </Column>
            </div>

            <div className="w-full mx-auto md:hidden md:w-5/12  mb-12">
              <Column>
                <img src={ConsArt.default.threeX} alt={"Construction site"} />
              </Column>
            </div>
          </div>

          <ContentView />
        </div>
      </div>
    </>
  )
}

const ContentView: FC = () => {
  useFetchData(async () => fetchCategories(), [])

  const [error, tryAgain] = useFetchData(async () => fetchCategories(), [])
  const categoryIds = useGetCategoryIds()

  if (categoryIds != null) {
    return (
      <div className="flex flex-col pb-8">
        <LandingProjectCategoriesTitleDiv>Project Categories</LandingProjectCategoriesTitleDiv>
        {/* <div className="flex flex-wrap mx-auto lg:-ml-6"> */}
        <CategoryWrapperDiv>
          {categoryIds?.map(id => (
            <CategoryTile id={id} />
          ))}
        </CategoryWrapperDiv>
        {/* </div> */}
      </div>
    )
  } else if (error != null) {
    return <ErrorView errorText={"Could not load projects"} tryAgain={tryAgain} />
  } else {
    return <FullScreenLoading />
  }
}
