import { AxiosInstance } from "axios"
import { CategoriesResponse, ContractsResponse, ProjectsResponse } from "../models/responseModels"

export class Api {
  constructor(private backend: AxiosInstance) {}

  async getCategories(): Promise<CategoriesResponse> {
    const response = await this.backend.get("/categories")
    return response.data
  }

  async getProjects(): Promise<ProjectsResponse> {
    const response = await this.backend.get("/projects")
    return response.data
  }

  async getContracts(projectId: string): Promise<ContractsResponse> {
    const response = await this.backend.get(`/contracts?project_ids=${projectId}`)
    return response.data
  }

  async contactProjectManager(contractId: string, fromNumber: string): Promise<void> {
    const body = {
      contract_id: contractId,
      user_contact_number: fromNumber,
    }
    this.backend
      .post(`/contact_project_manager`, body)
      .then(() => alert("Call placed"))
      .catch(() => alert("Something went wrong"))
  }
}
