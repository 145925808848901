import { Route, Routes } from "react-router-dom"
import React from "react"
import { TopBar } from "./TopBar"
import styled, { createGlobalStyle } from "styled-components"
import {
  MAIN_MAX_WIDTH,
  MEDIUM_SCREEN_WIDTH,
  MIN_WIDTH,
  MOBILE_MAX_WIDTH,
  MOBILE_SCREEN_WIDTH,
} from "../../utils/appConstants"
import { CategoriesScreen } from "../../../shared-client/components/categories/CategoriesScreen"
import { ProjectsScreen } from "../../../shared-client/components/projects/ProjectsScreen"
import { ProjectScreen } from "../../../shared-client/components/projects/ProjectScreen"
import { ContractModal } from "../../../shared-client/components/feed/ContractModal"
import { LandingPageScreen } from "../../../shared-client/landing/LandingPage"
import { ContactManagerModal } from "../../../shared-client/components/feed/ContactManagagerModal"
import { Footer } from "./Footer"

export const RootStyle = createGlobalStyle`
  #root {
    display: flex;
    flex-direction: column;
  }
`

export const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 0px 20px;
  min-width: ${MIN_WIDTH};
  overflow-y: auto;

  @media only screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
    padding: 0 10px;
  }
`

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: min(100%, ${MAIN_MAX_WIDTH});
  height: 100%;
  margin: 0 auto;

  @media only screen and (max-width: ${MOBILE_SCREEN_WIDTH}px) {
    width: min(100%, ${MOBILE_MAX_WIDTH});
  }
`

export const Main = (): JSX.Element => {
  return (
    <>
      <TopBar />
      <ContentContainer>
        <MainContent>
          {/* <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vS9isUPah-C4cbFDWJeu_QQ_jnDuyAYJYiQQi2z8NAhP8mfkdiN0toKLxWhPQcZmMvMI1KnDStxX-MZ/pubhtml?widget=true&amp;headers=false"></iframe> */}

          <Routes>
            <Route path="/" element={<LandingPageScreen />} />
            <Route path="/categories" element={<CategoriesScreen />} />
            <Route path="/projects" element={<ProjectsScreen />} />
            <Route path="/projects/:id" element={<ProjectScreen />} />
          </Routes>
          <Footer />
        </MainContent>
      </ContentContainer>
      <ContractModal />
      <ContactManagerModal />
    </>
  )
}
