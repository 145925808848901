import React, { FC } from "react"
import { Icon } from "../../../platform-impl/components/common/Icon"
import { useGetCategory, useGetCategoryFilters, useSetCategoryFilters } from "../../store/selectors"
import { addParamsToUrl, useGetParamForKey, useNavigateToPath } from "../../../platform-impl/utils/navigation"
import { getCategoryIconPathFromName } from "../../utils/CategoryUtils"

export interface Props {
  id: string
}

export const CategorySelectionTile: FC<Props> = ({ id }) => {
  const category = useGetCategory(id)
  const currentCategoryId = useGetParamForKey("id")

  const selected = id === currentCategoryId
  const navigateToCategories = useNavigateToPath(addParamsToUrl("/categories", { id: category?._id ?? "hi" }))

  if (category == null) return null

  return (
    <div
      className={`border-solid  border-2  shadow-md mr-3 md:mb-4 min-w-fit w-8/12 p-2 rounded-md cursor-pointer`}
      onClick={navigateToCategories}
    >
      <div className="flex items-center">
        <Icon marg={[0, "def", 0, 0]} size={30} paths={getCategoryIconPathFromName(category.resource_name)} alt={""} />
        <div className={`${selected && "text-[#1AC883]"} font-semibold`}> {category.resource_name} </div>
      </div>
    </div>
  )
}
