import React from "react"
import { Text } from "../common/Text"

export const Footer = (): JSX.Element => {
  return (
    <div className="mt-12 flex flex-wrap p-4 bg-[#f0f0f0]">
      <div className="mx-4">
        <Text>Legal Name</Text>

        <Text bold>M/s: DIHADI</Text>
      </div>

      <div className="mx-4">
        <Text>Contact number</Text>
        <Text bold>+91 7433099922</Text>
      </div>

      <div className="mx-4">
        <Text>Company Address </Text>

        <Text bold>A605 Shivpriya Towers, Jaitala Last Bus Stop, Jaitala, Nagpur 440036, Maharashtra India</Text>
      </div>

      <div className="mx-4">
        <Text>Email</Text>

        <Text bold>tech@dihadi.co.in</Text>
      </div>

      <div className="mx-4">
        <Text>Company PAN </Text>

        <Text bold>AAVFD9992R</Text>
      </div>

      <div className="mx-4">
        <Text>Company Registration Type </Text>

        <Text bold>Partnership deed</Text>
      </div>
    </div>
  )
}
