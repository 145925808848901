import { useState, DependencyList, useCallback, useEffect } from "react"
import { Error } from "../store/loadingSlice"

export const useFetchData = (
  dataFn: () => Promise<void>,
  deps: DependencyList
): [error: Error | undefined, tryAgain: () => Promise<void>] => {
  const [error, setError] = useState<Error | undefined>()
  const fetchWithErrorHandling = async (): Promise<void> => {
    try {
      setError(undefined)
      await dataFn()
    } catch (error) {
      console.error("Error fetching data: ", error)
      setError({ error })
    }
  }

  const fetchData = useCallback(fetchWithErrorHandling, deps)
  useEffect(() => void fetchData(), [fetchData])
  return [error, fetchData]
}
