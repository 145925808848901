import React, { Dispatch, useCallback, useEffect, useState } from "react"
import { Text } from "../../../platform-impl/components/common/Text"
import { Icon } from "../../../platform-impl/components/common/Icon"
import { Highways25 } from "../../../platform-impl/IconPaths"

export const ProjectsScreen = (): JSX.Element => {
  const projectCategories = [
    { title: "Metro" },
    { title: "Highways" },
    { title: "Bridges" },
    { title: "Industrial" },
    { title: "Railways" },
    { title: "Residential" },
  ]

  return (
    <>
      <div className="bg-[#FBFCFF]">
        <div className="flex flex-col ">
          <Text size={"60px"}>Project List</Text>
        </div>

        <div className="flex ">
          <div className="w-3/12 px-5 ">
            <div className="shadow-custom rounded-md px-3 py-1">
              {projectCategories.map(pc => (
                <div className="flex items-center">
                  <Icon paths={Highways25} alt={pc.title} size={45} marg={[0, "def", 0, 0]} />
                  <Text bold>{pc.title}</Text>
                </div>
              ))}
            </div>
          </div>
          <div className="w-8/12 px-2"></div>
          <div className="w-3/12 px-5  ">
            <div className="shadow-custom pb-10 rounded-md pt-2 px-3">
              <Text bold>Sort by </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
