import React, { forwardRef, useState } from "react"
import styled, { css } from "styled-components"
import { IconProps } from "../../../shared-client/props/IconProps"
import { mergeStyles } from "../../styles/utils"
import { withoutPropagation } from "../../utils/webUtils"
import { ViewStyled } from "./View"
import { BaseProps } from "../../../shared-client/props/BaseProps"

const IconStyled = styled(ViewStyled)<BaseProps & { tint?: boolean; interactive?: boolean; dim?: boolean }>`
  display: block;

  filter: ${props => (props.tint === true ? "var(--filter-la)" : props.dim === true ? "opacity(0.4)" : "none")};

  ${props =>
    props.interactive === true &&
    css<{ dim?: boolean }>`
      &:hover {
        filter: ${props => (props.dim === true ? "none" : "opacity(0.5) var(--filter-la)")};
      }

      &:active {
        filter: ${props => (props.dim === true ? "none" : "opacity(0.5) var(--filter-la-dark)")};
      }
    `}
`

export const Icon = forwardRef<HTMLImageElement, IconProps>(
  ({ styles, paths, size, filled, interactive, onTap, ...rest }, ref) => {
    const [hasMouse, setHasMouse] = useState(false)
    const pathSet =
      filled === true || (interactive === true && hasMouse) ? paths.filled ?? paths.default : paths.default
    return (
      <IconStyled
        {...rest}
        as={"img"}
        ref={ref}
        className={mergeStyles(styles)}
        width={size}
        height={size}
        src={paths.default.oneX}
        interactive={interactive}
        onMouseOver={() => setHasMouse(true)}
        onMouseOut={() => setHasMouse(false)}
        srcSet={`${pathSet.oneX} 1x,${pathSet.oneX} 2x, ${pathSet.oneX} 3x`}
        onClick={withoutPropagation(onTap)}
      />
    )
  }
)
