import React, { FC } from "react"
import { Icon } from "../../../platform-impl/components/common/Icon"
import { FireIcon } from "../../../platform-impl/IconPaths"
import { useFetchData } from "../../hooks/useFetchData"
import { fetchCategories, fetchGoogleSheetData, fetchProjects } from "../../store/fetchActions"
import { useGetCategoryIds, useGetProjectIds } from "../../store/selectors"
import { ProjectTile } from "../feed/ProjectTile"
import { CategorySelectionTile } from "../feed/CategorySelectionTile"
import { MEDIUM_SCREEN_WIDTH } from "../../../platform-impl/utils/appConstants"
import styled from "styled-components"
import { ErrorView } from "../common/ErrorView"
import { FullScreenLoading } from "../common/FullScreenLoading"

const CategorySelectTilesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
    flex-direction: row;
    width: 100%;
  }
`

const LandingProjectCategoriesTitleDiv = styled.div`
  font-size: 60px;
  font-weight: bold;
  @media only screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const CategoriesScreen = (): JSX.Element => {
  return (
    <>
      <div className="flex flex-col mt-3 w-full">
        <div className="flex items-center">
          <div className=" hidden md:block -ml-5">
            <Icon size={80} paths={FireIcon} alt="hot projects" />
          </div>
          <LandingProjectCategoriesTitleDiv>Hot Projects</LandingProjectCategoriesTitleDiv>
        </div>
      </div>
      <ContentView />
    </>
  )
}

const ContentView: FC = () => {
  useFetchData(async () => fetchCategories(), [])
  const [errorProjects, tryAgainProjects] = useFetchData(async () => fetchProjects(), [])

  const categoryIds = useGetCategoryIds()
  const projectIds = useGetProjectIds()

  const feed = categoryIds && projectIds

  if (feed != null) {
    return (
      <div className="flex flex-wrap">
        <div className="w-full md:w-2/12 mt-4 overflow-auto">
          <CategorySelectTilesWrapper>
            {categoryIds?.map(id => (
              <CategorySelectionTile id={id} />
            ))}
          </CategorySelectTilesWrapper>
        </div>
        <div className="w-full  md:w-10/12 flex flex-wrap md:px-10 mt-3  justify-between">
          {projectIds?.map(id => (
            <ProjectTile key={id} id={id} />
          ))}
        </div>
      </div>
    )
  } else if (errorProjects != null) {
    return <ErrorView errorText={"Could not load projects"} tryAgain={tryAgainProjects} />
  } else {
    return <FullScreenLoading />
  }
}
