import ReactModal, { Styles } from "react-modal"
import { FC } from "react"
import { Text } from "./Text"
import { Icon } from "./Icon"
import { ClearThin15 } from "../../IconPaths"
import { Row } from "./Row"
import { CONTENT_PADDING, DEFAULT_SPACING, View } from "./View"
// import { ScrollView } from "./ScrollView"
import { Column } from "./Column"
import { ModalProps } from "../../../shared-client/props/ModalProps"

let modalCount = 0
export const Modal: FC<ModalProps> = ({ children, width, open, close, contentLabel, title }) => {
  return (
    <ReactModal
      onAfterOpen={() => {
        document.body.style.overflow = "hidden"
        modalCount++
      }}
      onAfterClose={() => {
        if (--modalCount === 0) {
          document.body.style.overflow = "unset"
        }
      }}
      isOpen={open}
      onRequestClose={close}
      contentLabel={contentLabel}
      style={styles(width)}
      // overlayElement={(props, children) => (
      //   <View {...props} onTap={close}>
      //     {children}
      //   </View>
      // )}
    >
      <Row pad={CONTENT_PADDING} onTap={() => {}}>
        {title != null ? (
          <Text flex={1} size={"large"} bold>
            {title}
          </Text>
        ) : (
          <View flex={1} />
        )}
        <Icon
          marg={[-DEFAULT_SPACING, -DEFAULT_SPACING, 0, 0]}
          size={20}
          paths={ClearThin15}
          alt={"Close Modal"}
          onTap={close}
        />
      </Row>
      {/* <ScrollView maxHeight={"75vh"} pad={[1, CONTENT_PADDING, CONTENT_PADDING, CONTENT_PADDING]} onTap={() => {}}> */}
      <Column alignSelf={"stretch"}>{children}</Column>
      {/* </ScrollView> */}
    </ReactModal>
  )
}

const styles = (width: number | string): Styles => ({
  overlay: {
    background: "#28282888",
  },
  content: {
    width: typeof width === "number" ? `${width}px` : width,
    position: "relative",
    maxWidth: "90%",
    padding: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -55%)",
  },
})
