import React, { forwardRef } from "react"
import { ViewStyled } from "./View"
import { ContainerProps } from "../../../shared-client/props/ContainerProps"
import styled, { css } from "styled-components"
import { withoutPropagation } from "../../utils/webUtils"
import { mergeStyles } from "../../styles/utils"

// const ColumnStyled = styled(ViewStyled)<ContainerProps>`
//   display: flex;
//   flex-direction: ${props => (props.reverse === true ? "column-reverse" : "column")};
//   flex-wrap: ${props => props.flexWrap ?? "nowrap"};

//   // HACK: prevent clipping of child component hover effects when there's a border radius
//   // Cannot set this generally as sticky things (like comment footer) will not render properly
//   // See question (not answer):
//   // https://stackoverflow.com/questions/15023511/css-border-radius-parent-child-issue-with-hover-positionabsolute
//   ${props =>
//     props.bordRadius !== undefined &&
//     css`
//       overflow: hidden;
//     `}
// `

// export const Column = forwardRef<HTMLDivElement, ContainerProps>(({ styles, onTap, ...rest }, ref): JSX.Element => {
//   return <ColumnStyled {...rest} ref={ref} className={mergeStyles(styles)} onClick={withoutPropagation(onTap)} />
// })

import { View } from "./View"

export const Column = styled(View).attrs<ContainerProps>(({ styles }) => ({
  className: mergeStyles(styles),
}))<ContainerProps>`
  display: flex;
  flex-direction: ${props => (props.reverse === true ? "column-reverse" : "column")};
  flex-wrap: ${props => props.flexWrap ?? "nowrap"};
`

// overflow-y: ${props => props.overflowY ?? "none"};
