import styled, { css } from "styled-components"
import { TextProps } from "../../../shared-client/props/TextProps"
import { mergeStyles } from "../../styles/utils"
import { CustomLinkify } from "./CustomLinkify"
import React, { forwardRef } from "react"
import { withoutPropagation } from "../../utils/webUtils"
import { ViewStyled } from "./View"
import { LA_RED, LA_RED_DARK, TEXT_DARK, TEXT_DARK_DIM, TEXT_LIGHT } from "../../../shared-client/constants/colors"

export const FONT_DEFAULT = "-apple-system, BlinkMacSystemFont, HelveticaNeue, Roboto, sans-serif"
export const TextStyled = styled(ViewStyled)<TextProps & { hasAction?: boolean }>`
  --font-default: ${FONT_DEFAULT};
  --font-light: "HelveticaNeue-Light", ${FONT_DEFAULT};

  font-family: ${props => (props.light === true ? "var(--font-light)" : "var(--font-default)")};
  font-size: ${props => props.size ?? "medium"};
  font-weight: ${props => (props.bold === true ? "bold" : "normal")};
  font-style: ${props => (props.italic === true ? "italic" : "normal")};

  color: ${props =>
    props.accent === true
      ? LA_RED
      : props.lightText === true
      ? TEXT_LIGHT
      : props.dim === true
      ? TEXT_DARK_DIM
      : TEXT_DARK};

  white-space: pre-wrap;
  word-wrap: anywhere;
  display: block;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  line-clamp: ${props => props.maxLines ?? 1};
  -webkit-line-clamp: ${props => props.maxLines ?? 1};

  line-height: ${props => props.lineHeight ?? "none"}
    ${props =>
      props.hasAction === true &&
      css<TextProps>`
        text-align: start;
        transition: color 150ms;
        background-color: inherit;

        ${props =>
          props.pad === undefined &&
          css`
            padding: 0; /* turn off default padding for buttons */
          `}
        ${props =>
          props.bordStyle === undefined &&
          css`
            border-style: none; /* turn off default padding for buttons */
          `}
      ${props =>
          props.backgroundColor === undefined &&
          css`
            background-color: inherit; /* turn off defaults for buttons */
          `}
      &:hover {
          color: ${LA_RED_DARK};
        }
      `};
`

export const Text = forwardRef<HTMLSpanElement, TextProps>(
  ({ styles, linkify, lightText, parseHtml, onTap, children, ...rest }, ref) => {
    let innerHtml = undefined
    if (parseHtml === true) {
      innerHtml = { __html: children }
      // Don't show the children separately if set as inner HTML
      children = undefined
    }
    return (
      <TextStyled
        as={onTap !== undefined ? "button" : "span"}
        {...rest}
        lightText={lightText}
        ref={ref}
        className={mergeStyles(styles)}
        dangerouslySetInnerHTML={innerHtml}
        onClick={withoutPropagation(onTap)}
        hasAction={onTap !== undefined}
      >
        {linkify === true ? <CustomLinkify light={lightText}>{children}</CustomLinkify> : children}
      </TextStyled>
    )
  }
)
