import React, { FC, useState } from "react"
import { Modal } from "../../../platform-impl/components/common/Modal"
import { ContractModalProps } from "../../props/ContractModalProps"
import {
  useGetContactManagerModalProps,
  useGetContractModalProps,
  useSetContactManagerModalProps,
  useSetContractModalProps,
} from "../../store/selectors"
import { Input } from "../../../platform-impl/components/common/Input"
import { DT_GREEN } from "../../constants/colors"
import { Button } from "../../../platform-impl/components/common/Button"
import { ContactManagerModalProps } from "../../props/ContactManagerModalProps"
import { api } from "../../api/apiController"

export const ContactManagerModal: FC = () => {
  const props = useGetContactManagerModalProps()
  return props != null ? <ContactManagerModalImpl {...props} /> : null
}

export const ContactManagerModalImpl: FC<ContactManagerModalProps> = ({ contract_id }) => {
  const setContactManagerModalProps = useSetContactManagerModalProps()
  const close = (): void => setContactManagerModalProps(undefined)
  const [phoneNumber, setPhoneNumber] = useState("")

  const handleCallSubmit = async (): Promise<void> => {
    await api.contactProjectManager(contract_id, phoneNumber)
  }

  return (
    <Modal width={400} open={true} close={close} contentLabel={"Contract Listing"} title={"Contract Listing"}>
      <div className="p-4 ">
        <div className="flex items-stretch justify-between border-[#2AFD98] border-solid border-2 rounded-md">
          <Input
            maxLines={1}
            value={phoneNumber}
            onValueUpdate={value => {
              setPhoneNumber(value)
            }}
            type="tel"
            placeholder={"Enter your phone number"}
            pad={"def"}
            //   bordRadius={"def"}
            //   bordStyle={"solid"}
            //   borderWidth={3}
            borderColor={DT_GREEN}
            color={DT_GREEN}
            width={"80%"}
            activeColor="#F9FBFF"
            backgroundColor="#F9FBFF"

            // activeColor={DT_GREEN}
            // backgroundColor={DT_GREEN}
          />
          <Button title="Place Call" type="primary" onTap={handleCallSubmit} />
        </div>
      </div>
    </Modal>
  )
}
