export const CONTENT_BACKGROUND = "white"
export const LA_RED_DISABLED = "#66033caa"
export const LA_LIGHT = "#96336c"
export const LA_RED_DARK = "#46031c"
export const LA_RED_BG_TOUCH = "#fbf5f5"
export const LA_RED_BG = "#fff9f9"
export const TOUCH_HIGHLIGHT = "#fdfcfc"
export const DIVIDER = "#dddddd"
export const ICON_DEEMPHASIZED = "#666666"
export const LA_ERROR = "#cc0000"

export const TEXT_DARK = "black"
export const TEXT_DARK_DIM = ICON_DEEMPHASIZED
export const TEXT_LIGHT = "white"

export const DT_GREEN = "#2AFD98"
export const DT_GREEN_DARK = "#1AC883"

// export const LA_RED = DT_GREEN

export const LA_RED = "#66033c"
