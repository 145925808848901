import {
  Bridges25,
  Highways25,
  IconPaths,
  Industrial25,
  Metro25,
  Railways25,
  Residential25,
} from "../../platform-impl/IconPaths"

export const getCategoryIconPathFromName = (categoryName: string | undefined): IconPaths => {
  let path = Metro25
  if (categoryName == null) return Metro25
  switch (categoryName) {
    case "Bridges":
      path = Bridges25
      break

    case "Highways":
      path = Highways25
      break

    case "Metro":
      path = Metro25
      break

    case "Industrial":
      path = Industrial25
      break

    case "Railways":
      path = Railways25
      break

    case "Residential":
      path = Residential25
      break
    default:
      path = Metro25
  }
  return path
}
