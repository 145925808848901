import { MouseEventHandler } from "react"

export const withoutPropagation = <T>(
  action: MouseEventHandler<T> | (() => void) | Promise<void> | undefined
): MouseEventHandler<T> | undefined => {
  if (action === undefined) return undefined
  return event => {
    if ("then" in action) {
      void action.then().catch()
    } else {
      action(event)
    }
    event.stopPropagation()
  }
}
