import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { RefObject, useMemo } from "react"

export const HOME_PATH = "/"
const CATEGORIES_PATH = "/categories"
const POSTS_PATH = "/posts"
const USERS_PATH = "/users"
const GROUPS_PATH = "/groups"
const SETTINGS_PATH = "/settings"
const SEARCH_PATH = "/search"
const CHATS_PATH = "/chats"
const DEALS_PATH = "/deals"
const EVENTS_PATH = "/events"
const EDIT_PROFILE_PATH = "/profile/edit"

export const useNavigateToPath = (path: string, params?: Record<string, string>): (() => void) => {
  const navigate = useNavigate()
  return () => navigate(addParamsToUrl(path, params))
}

export const useNavigateToPathForId = (path: string): ((id: string, params?: Record<string, string>) => void) => {
  const navigate = useNavigate()
  return (id: string, params?: Record<string, string>) => navigate(addParamsToUrl(`${path}/${id}`, params))
}

export const useHomeNavigate = (): (() => void) => useNavigateToPath(HOME_PATH)
export const useIsHome = (): boolean => useLocation().pathname === "/"
export const useCategoriesNavigate = (): (() => void) => useNavigateToPath(CATEGORIES_PATH)

// export const useGetIdParam = (): number => {
//   return parseInt(useRoute<any>().params?.id ?? "-1")
// }

export const useGetIdParam = (): string => {
  return useParams().id ?? "-1"
}

export const useGetParamForKey = (key: string): string => {
  const [searchParams] = useSearchParams()
  return searchParams.get(key) ?? "-1"
}

export const usePostNavigate = (postId: number, commentId?: number): (() => void) => {
  const navigate = useNavigate()
  let path = `${POSTS_PATH}/${postId}`
  if (commentId !== undefined) {
    path += `/${commentId}`
  }
  return () => navigate(path)
}

export const useNewPostNavigate = (): ((postId: number) => void) => {
  const navigate = useNavigate()
  return (postId: number) => navigate(`${POSTS_PATH}/${postId}`)
}

// export const useUserNavigate = (userId: number): (() => void) => useNavigateToPath(USERS_PATH, userId)

export const useGroupsNavigate = (): (() => void) => useNavigateToPath(GROUPS_PATH)
export const useGroupNavigate = (groupId: number, page?: number): (() => void) => {
  const navigate = useNavigate()
  return () => navigate(`${GROUPS_PATH}/${groupId}${page !== undefined ? `?page=${page}` : " "}`)
}
export const useIsInGroups = (): boolean => {
  const inGroups = useIsInPath(GROUPS_PATH)
  const inPosts = useIsInPath(POSTS_PATH)
  return inGroups || inPosts
}

export const useSearchNavigate = (): (() => void) => useNavigateToPath(SEARCH_PATH)
export const useIsInSearch = (): boolean => useIsInPath(SEARCH_PATH)

// export const useDealNavigate = (dealId: number): (() => void) => useNavigateToPath(DEALS_PATH, dealId)
// export const useEventNavigate = (eventId: number): (() => void) => useNavigateToPath(EVENTS_PATH, eventId)

export const useNavigateEditUser = (): (() => void) => useNavigateToPath(EDIT_PROFILE_PATH)

export const useSettingsNavigate = (): (() => void) => useNavigateToPath(SETTINGS_PATH)
export const useIsInSettings = (): boolean => useIsInPath(SETTINGS_PATH)
export const useNotificationsSettingsNavigate = (): (() => void) => useNavigateToPath(`${SETTINGS_PATH}/notifications`)

export const useChatsNavigate = (): (() => void) => useNavigateToPath(CHATS_PATH)
export const useIsInChats = (): boolean => useIsInPath(CHATS_PATH)

export const useIsInDeals = (): boolean => useIsInPath(DEALS_PATH)
export const useIsInEvents = (): boolean => useIsInPath(EVENTS_PATH)

export const useChatNavigate = (threadId?: number, userIds?: number[]): (() => void) => {
  const navigate = useNavigate()
  let path: string
  if (threadId === undefined && userIds === undefined) {
    throw new Error("Both threadId and userIds cannot be undefined")
  }
  if (userIds !== undefined) {
    path = `${CHATS_PATH}/ids/${encodeUserIds(userIds)}`
  } else {
    path = `${CHATS_PATH}/${threadId}`
  }
  return () => navigate(path)
}

export const useGetPageParam = (): number => {
  const [searchParams] = useSearchParams()
  return parseInt(searchParams.get("page") ?? "1")
}

export const useGetChatParams = (): { threadIdParam?: number; userIds?: number[] } => {
  const params = useParams()
  const threadIdParam = params.id !== undefined ? parseInt(params.id) : undefined
  const userIdsString = params.ids
  const userIds = useMemo(
    () => (userIdsString !== undefined ? decodeUserIds(userIdsString) : undefined),
    [userIdsString]
  )
  return { threadIdParam, userIds }
}

export const createSettingsUrl = (): string => `/settings`

const useIsInPath = (targetPath: string): boolean => {
  const currentPath = useLocation().pathname
  return currentPath.startsWith(targetPath)
}

export const useScrollToTop = (ref: RefObject<any>): void => {} // NOOP on web

const originalTitle = document.title

// React Native side uses a hook, hence the "use" naming convention
export const useSetTitle = (title: string | undefined): void => {
  document.title = title ?? originalTitle
}

export const resetTitle = (): void => {
  document.title = originalTitle
}

export const encodeUserIds = (userIds: number[]): string => encodeURIComponent(JSON.stringify(userIds))
const decodeUserIds = (userIdsString: string): number[] => JSON.parse(decodeURIComponent(userIdsString))

export const addParamsToUrl = (path: string, params?: Record<string, string>): string => {
  const url = new URL(path, window.location.origin)
  if (params != null) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }
  return `${url.pathname}${url.search}`
}
