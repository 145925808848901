import axios from "axios"
import { DEVELOPMENT_HOST, PRODUCTION_HOST, STAGING_HOST } from "../constants/hosts"
import { Api } from "./api"
import { BackendEnvironment } from "./apiConstants"

export const getBaseUriForHost = (host: string): string => {
  if (host.includes("localhost")) {
    return `http://${host}`
  }
  return `https://${host}`
}

export const getHost = (env: BackendEnvironment): string => {
  switch (env) {
    case "staging":
      return STAGING_HOST
    case "production":
      return PRODUCTION_HOST
    case "development":
    default:
      return DEVELOPMENT_HOST
  }
}

const logData = (msg: string, data: any): void => log(msg, data, undefined)
const logError = (msg: string, error: any): void => log(msg, undefined, error)
const log = (msg: string, data: any, error: any): void => {
  if (error != null) {
    console.error(msg, error)
  } else {
    console.log(msg, data)
  }
}

const inititalizeApi = (host: string): Api => {
  // const apiUri = `${getBaseUriForHost(host)}/api/v1`
  const apiUri = `${getBaseUriForHost(host)}`
  const backend = axios.create({
    baseURL: apiUri,
    timeout: 10000,
  })

  backend.interceptors.response.use(response => {
    const isImage = response.data?.type?.includes("image") // dont log image
    if (isImage === null) {
      logData(response.data.responseUrl, response.data)
    }
    return response
  })

  // backend.interceptors.request.use(async error => {
  //   logError("Error on request", error)
  //   return Promise.reject(error)
  // })

  return new Api(backend)
}

// export default inititalizeApi(getHost("development"))
export let api = inititalizeApi(getHost("staging"))
