import React, { forwardRef } from "react"
import { ViewStyled } from "./View"
import { ContainerProps } from "../../../shared-client/props/ContainerProps"
import styled from "styled-components"
import { withoutPropagation } from "../../utils/webUtils"
import { mergeStyles } from "../../styles/utils"

const RowStyled = styled(ViewStyled)<ContainerProps>`
  display: flex;
  flex-direction: ${props => (props.reverse === true ? "row-reverse" : "row")};
  flex-wrap: ${props => props.flexWrap ?? "nowrap"};
`

export const Row = forwardRef<HTMLDivElement, ContainerProps>(({ styles, onTap, ...rest }, ref): JSX.Element => {
  return <RowStyled {...rest} ref={ref} className={mergeStyles(styles)} onClick={withoutPropagation(onTap)} />
})
