export const TOP_BAR_HEIGHT = 70

export const MIN_WIDTH = "300px"
// export const MAIN_MAX_WIDTH = "82ch"
export const MAIN_MAX_WIDTH = "150ch"
export const MOBILE_MAX_WIDTH = "50ch"

export const NAV_ICON_WIDTH = 70
export const NAV_ICON_MIN_WIDTH = 50

export const MEDIUM_SCREEN_WIDTH = 992
export const MOBILE_SCREEN_WIDTH = 480
