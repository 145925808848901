import { createModelSlice, ModelSlice } from "./modelSlice"
import { createWithEqualityFn } from "zustand/traditional"
import { produce } from "immer"
import { createLoadedSlice, LoadedSlice } from "./loadedSlice"
import { createLocalSlice, LocalSlice } from "./localSlice"

export type StoreState = ModelSlice & LoadedSlice & LocalSlice

const useBoundStore = createWithEqualityFn<StoreState>(
  (...args) => ({
    ...createModelSlice(...args),
    ...createLoadedSlice(...args),
    ...createLocalSlice(...args),
  }),
  Object.is
)

export const useStore = <T>(selector: (state: StoreState) => T, equalityFn: (a: T, b: T) => boolean): T =>
  useBoundStore(selector, equalityFn)

export const setStateImmutable = (stateFn: (state: StoreState) => void): void => {
  useBoundStore.setState(produce(stateFn))
}

export const getState = useBoundStore.getState
