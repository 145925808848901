import { Category, Contract, Project } from "../models/model"
import { CategoryUi } from "../models/uiModels"
import { ContactManagerModalProps } from "../props/ContactManagerModalProps"
import { ContractModalProps } from "../props/ContractModalProps"
import { useStore } from "./store"
import { shallow } from "zustand/shallow"

export const getDummyEvents = (): any => {
  return { events: [] }
}

// export const useGetCategories = (): Record<number, Category> => useStore(state => state.categories, Object.is)

export const useGetCategory = (id: string | number | null): Category | undefined =>
  useStore(state => (id != null ? state.categories[id] : undefined), Object.is)

export const useGetProject = (id: string | null): Project | undefined =>
  useStore(state => (id != null ? state.projects[id] : undefined), Object.is)

export const useGetContract = (id: string | null): Contract | undefined =>
  useStore(state => (id != null ? state.contracts[id] : undefined), Object.is)

export const useGetCategoryIds = (): string[] | undefined => useStore(state => state.feeds.categories?.items, shallow)

export const useGetCategoryNamesToIds = (): Record<string, string> =>
  useStore(state => {
    const mapping: Record<string, string> = {}
    Object.values(state.categories).forEach(value => {
      mapping[value.resource_name] = value._id
    })
    return mapping
  }, Object.is)

export const useGetProjectIds = (): string[] | undefined => useStore(state => state.feeds.projects?.items, shallow)

export const useGetContractIds = (projectId: string): string[] | undefined =>
  useStore(state => state.feeds.contracts[projectId]?.items, shallow)

export const useGetContractModalProps = (): ContractModalProps | undefined =>
  useStore(state => state.modalProps.contract, Object.is)

export const useSetContractModalProps = (): ((props: ContractModalProps | undefined) => void) =>
  useStore(state => state.setters.modalProps.contract, Object.is)

export const useGetContactManagerModalProps = (): ContactManagerModalProps | undefined =>
  useStore(state => state.modalProps.contactManager, Object.is)

export const useSetContactManagerModalProps = (): ((props: ContactManagerModalProps | undefined) => void) =>
  useStore(state => state.setters.modalProps.contactManager, Object.is)

export const useGetCategoryFilters = (): Record<string, boolean> =>
  useStore(state => state.filters.categories, Object.is)

export const useSetCategoryFilters = (): ((categories: Record<string, boolean>) => void) =>
  useStore(state => state.setters.filters.categories, Object.is)
