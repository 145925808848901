import { RequiredWithUndefinedValues } from "../utils/propUtils"
import { Category, Contract, Cursor, DbObject, Project } from "./model"

const extractDbObject = (o: DbObject): RequiredWithUndefinedValues<DbObject> => ({
  _id: o._id,
})

export const extractCategory = (category: Category): RequiredWithUndefinedValues<Category> => ({
  ...extractDbObject(category),
  resource_type: category.resource_type,
  resource_name: category.resource_name,
  url: category.url,
})

export const extractProject = (project: Project): RequiredWithUndefinedValues<Project> => ({
  ...extractDbObject(project),
  location: project.location,
  contact_details: project.contact_details,
  name: project.name,
  photo_url: project.photo_url,
  number_of_available_contracts: project.number_of_available_contracts,
  total_value: project.total_value,
  list_of_contracts: project.list_of_contracts,
  project_tags: project.project_tags,
  project_category: project.project_category,
  number_of_views: project.number_of_views,
  company_name: project.company_name,
  project_description: project.project_description,
})

export const extractContract = (contract: Contract): RequiredWithUndefinedValues<Contract> => ({
  ...extractDbObject(contract),
  contact_details: contract.contact_details,
  link_to_rates: contract.link_to_rates,
  expected_deadline: contract.expected_deadline,
  subcategory: contract.subcategory,
  contract_tags: contract.contract_tags,
  project_id: contract.project_id,
  contract_description: contract.contract_description,
  available_work_items: contract.available_work_items,
  total_work_items: contract.total_work_items,
  contract_value: contract.contract_value,
})

export const extractCursor = (cursor: Cursor): RequiredWithUndefinedValues<Cursor> => ({
  page: cursor.page,
  prev: cursor.prev,
  next: cursor.next,
  last: cursor.last,
  count: cursor.count,
})
