import { StateCreator } from "zustand/esm"
import { StoreState } from "./store"
import { Feed, FeedIds } from "../models/uiModels"
import { resetActions } from "./resetStore"
import { getPersistedUserId } from "../utils/storage"
import { Cursor, DbObject } from "../models/model"
import { dedupe } from "../utils/jsUtils"
import { extractCursor } from "../models/extractFns"

export interface Error {
  error: any
}

export interface LoadedSlice {
  loginInfo: {
    userId: number | undefined
  }
  feeds: {
    categories: FeedIds | undefined
    projects: FeedIds | undefined
    contracts: Record<string, FeedIds>
  }
}

const getInitialState = (persistedUserId: number | undefined): LoadedSlice => ({
  loginInfo: {
    userId: persistedUserId,
  },

  feeds: {
    categories: undefined,
    projects: undefined,
    contracts: {},
  },
})

export const createLoadedSlice: StateCreator<StoreState, [], [], LoadedSlice> = set => {
  resetActions.push(() => set(getInitialState(undefined)))
  return getInitialState(getPersistedUserId())
}

export const insertIntoFeedMap = <Key extends string | number, T extends DbObject>(
  contentMap: Record<Key, FeedIds>,
  id: Key,
  item: T,
  sortFn: (a: string, b: string) => number
): void => {
  if (id in contentMap) {
    contentMap[id] = insertIntoFeed(contentMap[id], item, sortFn)!
  }
}

export const updateFeedMapData = <Key extends string | number>(
  contentMap: Record<Key, FeedIds>,
  id: Key,
  newData: FeedIds,
  sortFn: (a: string, b: string) => number
): void => {
  contentMap[id] = mergeFeeds(contentMap[id], newData, sortFn)
}

export const insertIntoFeed = <T extends DbObject>(
  feed: FeedIds | undefined,
  item: T,
  sortFn: (a: string, b: string) => number
): FeedIds | undefined => {
  if (feed == null) return undefined // If there's no data we don't want to insert a singular item into a feed

  // Let's construct a new content object but keep the existing metadata
  const newData = { items: [item._id], cursor: feed.cursor }
  return mergeFeeds<string>(feed, newData, sortFn)
}

export const mergeFeeds = <T>(
  oldData: Feed<T> | undefined,
  newData: Feed<T>,
  sortFn: (a: T, b: T) => number,
  dedupeFn: (list: T[]) => T[] = dedupe
): Feed<T> => {
  const newCursor = newData.cursor != null ? extractCursor(newData.cursor) : undefined
  return {
    items: dedupeFn([...(oldData?.items ?? []), ...newData.items]).sort(sortFn),
    cursor: mergeCursors(oldData?.cursor, newCursor),
  }
}

const mergeCursors = (oldCursor: Cursor | undefined, newCursor: Cursor | undefined): Cursor | undefined => {
  if (oldCursor == null) {
    return newCursor
  } else if (newCursor == null) {
    return oldCursor
  } else {
    if (newCursor.last > oldCursor.last && oldCursor.next == null) {
      oldCursor.next = oldCursor.last
    }
    const last = Math.max(oldCursor.last, newCursor.last)
    const next =
      newCursor.next == null ? null : oldCursor.next == null ? null : Math.max(oldCursor.next, newCursor.next)
    const page = newCursor.page
    const prev =
      newCursor.prev == null ? null : oldCursor.prev == null ? null : Math.min(oldCursor.prev, newCursor.prev)
    return { last, next, page, prev, count: newCursor.count }
  }
}

export const removeFeedId = (feed: FeedIds | undefined, id: string): void => {
  if (feed == null) return
  feed.items = feed.items.filter(oldId => oldId !== id)
}

export const groupMapKey = (groupId: number, page: number): string => `${groupId}|${page}`
