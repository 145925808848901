import Linkify from "react-linkify"
import * as React from "react"
import styled from "styled-components"

interface Props {
  light?: boolean
  children: React.ReactNode
}

const LinkStyled = styled.a<Props>`
  color: ${props => (props.light === true ? "var(--text-color-light)" : "var(--la-red)")};

  &:visited {
    color: ${props => (props.light === true ? "var(--la-bg)" : "var(--la-red-dark-dim)")};
  }

  &:hover,
  &:active {
    color: ${props => (props.light === true ? "var(--la-bg-hover)" : "var(--la-red-dark)")};
  }
`

export const CustomLinkify = ({ light, children }: Props): JSX.Element => {
  const componentDecorator = (decoratedHref: string, decoratedText: string, key: number): React.ReactNode => (
    <LinkStyled light={light} key={key} href={decoratedHref} onClick={event => event.stopPropagation()}>
      {decoratedText}
    </LinkStyled>
  )
  return <Linkify componentDecorator={componentDecorator}>{children}</Linkify>
}
