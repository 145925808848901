import React, { FC } from "react"
import { Icon } from "../../../platform-impl/components/common/Icon"
import { Text } from "../../../platform-impl/components/common/Text"
import { useGetCategory } from "../../store/selectors"
import { addParamsToUrl, useNavigateToPath } from "../../../platform-impl/utils/navigation"
import { getCategoryIconPathFromName } from "../../utils/CategoryUtils"

export interface Props {
  id: string
}

export const CategoryTile: FC<Props> = ({ id }) => {
  const category = useGetCategory(id)
  const navigateToCategories = useNavigateToPath(addParamsToUrl("/categories", { id: category?._id ?? "hi" }))
  return (
    <div className="px-4 border-solid  w-full py-2 sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 cursor-pointer ">
      <div
        className="flex px-4 py-6 flex-col items-center bg-[#F4F8FF] rounded-4 shadow-md"
        onClick={() => navigateToCategories()}
      >
        <Icon
          size={100}
          marg={[0, 0, "half", 0]}
          paths={getCategoryIconPathFromName(category?.resource_name)}
          alt={""}
        />
        <Text marg={["half", "auto"]} bold size={"24"}>
          {category?.resource_name}
        </Text>
      </div>
    </div>
  )
}
