import { InputProps } from "../../../shared-client/props/InputProps"
import styled from "styled-components"
import TextareaAutosize from "react-textarea-autosize"
import { ViewStyled } from "./View"
import { forwardRef, RefObject } from "react"

const SingleLineInputStyled = styled(ViewStyled)`
  outline: none;
  background-color: transparent;
`

const MultilineInputStyled = styled(ViewStyled)`
  resize: none;
  outline: none;
  background-color: transparent;
  border: none;
`

export const Input = forwardRef<{ focus: () => void }, InputProps>(
  ({ maxLines, value, onValueUpdate, bordRadius, ...rest }, ref): JSX.Element => {
    if (maxLines === 1) {
      return (
        <SingleLineInputStyled
          as={"input"}
          {...rest}
          ref={ref as RefObject<any>}
          bordRadius={bordRadius}
          value={value}
          onChange={(event: any) => {
            onValueUpdate(event.target.value)
          }}
        />
      )
    } else {
      return (
        <MultilineInputStyled
          as={TextareaAutosize}
          {...rest}
          ref={ref as RefObject<any>}
          value={value}
          maxRows={maxLines !== "none" ? maxLines : undefined}
          onChange={(event: any) => onValueUpdate(event.target.value)}
        />
      )
    }
  }
)
