/**
 * Wish there was a way to dynamically generate these but require() needs a compile-time constant.
 * Webpack 5 is supposed to support the dynamic use case but couldn't get it to work.
 */
export interface IconPaths {
  default: PathSet
  filled?: PathSet
}

export interface PathSet {
  oneX: string
  twoX: string
  threeX: string
}

export const ConsArt: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/ConsArt.png"),
    twoX: require("../shared-client/icons/fullConstArt.png"),
    threeX: require("../shared-client/icons/fullConstArt.png"),
  },
}

export const Chat25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/chat_25.png"),
    twoX: require("../shared-client/icons/chat_25@2x.png"),
    threeX: require("../shared-client/icons/chat_25@3x.png"),
  },
  filled: {
    oneX: require("../shared-client/icons/chat_filled_25.png"),
    twoX: require("../shared-client/icons/chat_filled_25@2x.png"),
    threeX: require("../shared-client/icons/chat_filled_25@3x.png"),
  },
}

export const ChatSend25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/chat_send_25.png"),
    twoX: require("../shared-client/icons/chat_send_25@2x.png"),
    threeX: require("../shared-client/icons/chat_send_25@3x.png"),
  },
  filled: {
    oneX: require("../shared-client/icons/chat_send_filled_25.png"),
    twoX: require("../shared-client/icons/chat_send_filled_25@2x.png"),
    threeX: require("../shared-client/icons/chat_send_filled_25@3x.png"),
  },
}

export const ChevronDown10: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/chevron_down_10.png"),
    twoX: require("../shared-client/icons/chevron_down_10@2x.png"),
    threeX: require("../shared-client/icons/chevron_down_10@3x.png"),
  },
}

export const Clear15: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/clear_15.png"),
    twoX: require("../shared-client/icons/clear_15@2x.png"),
    threeX: require("../shared-client/icons/clear_15@3x.png"),
  },
}

export const ClearThin15: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/clear_thin_15.png"),
    twoX: require("../shared-client/icons/clear_thin_15@2x.png"),
    threeX: require("../shared-client/icons/clear_thin_15@3x.png"),
  },
}

export const Drawer25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/drawer_25.png"),
    twoX: require("../shared-client/icons/drawer_25@2x.png"),
    threeX: require("../shared-client/icons/drawer_25@3x.png"),
  },
  filled: {
    oneX: require("../shared-client/icons/drawer_filled_25.png"),
    twoX: require("../shared-client/icons/drawer_filled_25@2x.png"),
    threeX: require("../shared-client/icons/drawer_filled_25@3x.png"),
  },
}

export const First25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/first_25.png"),
    twoX: require("../shared-client/icons/first_25@2x.png"),
    threeX: require("../shared-client/icons/first_25@3x.png"),
  },
}

export const Forum25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/forum_25.png"),
    twoX: require("../shared-client/icons/forum_25@2x.png"),
    threeX: require("../shared-client/icons/forum_25@3x.png"),
  },
  filled: {
    oneX: require("../shared-client/icons/forum_filled_25.png"),
    twoX: require("../shared-client/icons/forum_filled_25@2x.png"),
    threeX: require("../shared-client/icons/forum_filled_25@3x.png"),
  },
}

export const Heart25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/heart_25.png"),
    twoX: require("../shared-client/icons/heart_25@2x.png"),
    threeX: require("../shared-client/icons/heart_25@3x.png"),
  },
  filled: {
    oneX: require("../shared-client/icons/heart_filled_25.png"),
    twoX: require("../shared-client/icons/heart_filled_25@2x.png"),
    threeX: require("../shared-client/icons/heart_filled_25@3x.png"),
  },
}

export const Last25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/last_25.png"),
    twoX: require("../shared-client/icons/last_25@2x.png"),
    threeX: require("../shared-client/icons/last_25@3x.png"),
  },
}

export const More20: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/more_20.png"),
    twoX: require("../shared-client/icons/more_20@2x.png"),
    threeX: require("../shared-client/icons/more_20@3x.png"),
  },
}

export const Next25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/next_25.png"),
    twoX: require("../shared-client/icons/next_25@2x.png"),
    threeX: require("../shared-client/icons/next_25@3x.png"),
  },
}

export const Notifications25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/notification_25.png"),
    twoX: require("../shared-client/icons/notification_25@2x.png"),
    threeX: require("../shared-client/icons/notification_25@3x.png"),
  },
  filled: {
    oneX: require("../shared-client/icons/notification_filled_25.png"),
    twoX: require("../shared-client/icons/notification_filled_25@2x.png"),
    threeX: require("../shared-client/icons/notification_filled_25@3x.png"),
  },
}

export const Plus25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/plus_25.png"),
    twoX: require("../shared-client/icons/plus_25@2x.png"),
    threeX: require("../shared-client/icons/plus_25@3x.png"),
  },
}

export const Prev25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/prev_25.png"),
    twoX: require("../shared-client/icons/prev_25@2x.png"),
    threeX: require("../shared-client/icons/prev_25@3x.png"),
  },
}

export const Reply20: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/reply_20.png"),
    twoX: require("../shared-client/icons/reply_20@2x.png"),
    threeX: require("../shared-client/icons/reply_20@3x.png"),
  },
  filled: {
    oneX: require("../shared-client/icons/reply_filled_20.png"),
    twoX: require("../shared-client/icons/reply_filled_20@2x.png"),
    threeX: require("../shared-client/icons/reply_filled_20@3x.png"),
  },
}

export const ReplyTo20: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/reply_to_20.png"),
    twoX: require("../shared-client/icons/reply_to_20@2x.png"),
    threeX: require("../shared-client/icons/reply_to_20@3x.png"),
  },
}

export const Search25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/search_25.png"),
    twoX: require("../shared-client/icons/search_25@2x.png"),
    threeX: require("../shared-client/icons/search_25@3x.png"),
  },
}

export const Settings25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/settings_25.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
  filled: {
    oneX: require("../shared-client/icons/settings_filled_25.png"),
    twoX: require("../shared-client/icons/settings_filled_25@2x.png"),
    threeX: require("../shared-client/icons/settings_filled_25@3x.png"),
  },
}

export const Highways25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/highway_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const Company25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/company_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const Rupee25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/rupee_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const Government25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/government_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const Railways25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/railways_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const Location25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/location_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const Residential25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/residential_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}
export const Industrial25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/industrial_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const Bridges25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/bridges_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const Metro25: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/metro_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const DownCarrot: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/down_carrot_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const UpCarrot: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/up_carrot_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const FireIcon: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/1_fire_icon.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}

export const ConstructionProjectFallImage: IconPaths = {
  default: {
    oneX: require("../shared-client/icons/conpng.png"),
    twoX: require("../shared-client/icons/settings_25@2x.png"),
    threeX: require("../shared-client/icons/settings_25@3x.png"),
  },
}
