import React, { FC, useState } from "react"
import { Text } from "../../../platform-impl/components/common/Text"
import { Tag } from "./Tag"
import { useGetCategory, useGetProject } from "../../store/selectors"
import { useGetParamForKey, useNavigateToPathForId } from "../../../platform-impl/utils/navigation"

export interface ProjectTypeTileProps {
  id: string
}

export const ProjectTile: FC<ProjectTypeTileProps> = ({ id }) => {
  const projectNavigate = useNavigateToPathForId("/projects")
  const project = useGetProject(id)

  const categoryId = useGetParamForKey("id")
  const category = useGetCategory(categoryId)

  if (category != null && project?.project_category !== category?.resource_name) return null

  return (
    <div className="p-2 w-full md:w-1/2 pb-4 mb-2 rounded-md cursor-pointer" onClick={() => projectNavigate(id)}>
      <div className="flex flex-col rounded-4 overflow-hidden shadow-lg">
        <div className=" bg-[#D9E3FF]  rounded-md overflow-clip h-80">
          <img src={project?.photo_url} alt="Your Image" className="object-cover h-full w-full" />
        </div>

        <div className="p-3">
          <div className="flex justify-between items-center mt-2">
            <Text size={"16px"} bold>
              {project?.name} - {project?.project_category}
            </Text>
            <Text size={"16px"} bold>
              ₹{project?.total_value}Cr
            </Text>
          </div>
          <div className="mb-3">{project?.company_name}</div>

          <div className="my-3 flex">
            {project?.project_tags.map(tag => (
              <Tag text={tag} key={tag} />
            ))}
          </div>

          <div className="mt-8 flex justify-end">
            <Text marg={[0, "def", 0, 0]}>Available Contracts: </Text>
            <Text bold>{project?.number_of_available_contracts} </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
