import React, { forwardRef } from "react"
import { mergeStyles } from "../../styles/utils"
import { withoutPropagation } from "../../utils/webUtils"
import styled, { css } from "styled-components"
import { BaseProps, Spacing, SpacingShorthand } from "../../../shared-client/props/BaseProps"

export const ViewStyled = styled.div<BaseProps>`
  flex: ${props => props.flex};
  width: ${props => getSizeValue(props.width)};
  min-width: ${props => getSizeValue(props.minWidth)};
  height: ${props => getSizeValue(props.height)};
  padding: ${props => getShorthandValue(props.pad)};
  margin: ${props => getShorthandValue(props.marg)};
  gap: ${props => getSpacingValue(props.gap)};
  justify-content: ${props => props.justifyContent};
  align-self: ${props => props.alignSelf};
  align-items: ${props => props.alignItems};

  border-radius: ${props => getSpacingValue(props.bordRadius)};
  border-color: ${props => props.borderColor};
  border-style: ${props => props.bordStyle};
  border-width: ${props => getBorderWidthValue(props.borderWidth)};
  color: ${props => props.color}

  background-color: ${props => props.backgroundColor};

  opacity: ${props => props.opacity};

  ${props =>
    props.activeColor !== undefined &&
    css`
      transition: background-color 150ms;

      &:hover,
      &:active {
        background-color: ${props.activeColor};
      }
    `};
`

export const View = forwardRef<HTMLDivElement, BaseProps>(({ styles, onTap, ...rest }, ref): JSX.Element => {
  return <ViewStyled {...rest} ref={ref} className={mergeStyles(styles)} onClick={withoutPropagation(onTap)} />
})

const getSizeValue = (size: string | number | undefined): string | undefined =>
  size === undefined ? undefined : typeof size === "number" ? `${size}px` : size

const getShorthandValue = (shorthand: SpacingShorthand | undefined): string | undefined => {
  if (shorthand === undefined) {
    return undefined
  } else if (!Array.isArray(shorthand)) {
    return getSpacingValue(shorthand)
  } else {
    return shorthand.map(getSpacingValue).join(" ")
  }
}

const getSpacingValue = (spacing: Spacing | undefined): string | undefined => {
  switch (spacing) {
    case undefined:
      return undefined
    case "auto":
      return "auto"
    case "def":
      return `${DEFAULT_SPACING}px`
    case "double":
      return `${DOUBLE_SPACING}px`
    case "half":
      return `${HALF_SPACING}px`
    case "small":
      return `${SMALL_SPACING}px`
    default:
      return `${spacing}px`
  }
}

const getBorderWidthValue = (
  borderWidth: number | [number, number, number, number] | undefined
): string | undefined => {
  if (borderWidth === undefined) {
    return undefined
  } else if (!Array.isArray(borderWidth)) {
    return `${borderWidth}px`
  } else {
    return borderWidth.map(width => `${width}px`).join(" ")
  }
}

export const DEFAULT_SPACING = 10
export const DOUBLE_SPACING = DEFAULT_SPACING * 2
export const HALF_SPACING = DEFAULT_SPACING / 2
export const SMALL_SPACING = 2
export const CONTENT_PADDING = 15
