import React from "react"
import { Text } from "../../../platform-impl/components/common/Text"
import { Button } from "../../../platform-impl/components/common/Button"

interface Props {
  errorText: string
  tryAgain: () => void | Promise<void>
}

export const ErrorView = ({ errorText, tryAgain }: Props): JSX.Element => {
  return (
    <div className="h-100 flex flex-col items-center justify-center">
      <Text marg={[0, 0, "double", 0]} bold accent>
        {errorText}
      </Text>
      <Button type={"primary"} title={"Try again"} onTap={() => tryAgain()} />
    </div>
  )
}
