import { StateCreator } from "zustand/esm"
import { StoreState } from "./store"
import { produce } from "immer"
import { resetActions } from "./resetStore"
import { ContractModalProps } from "../props/ContractModalProps"
import { ContactManagerModalProps } from "../props/ContactManagerModalProps"

/**
 * State that's local only, never persisted to the server
 */
export interface LocalSlice {
  //   app: {
  //     redirectMobileScreen: string | undefined
  //   }
  //   search: {
  //     term: string
  //   }
  //   sidebar: {
  //     new_deal: string | null
  //   }
  //   badgeCounts: {
  //     feed: number
  //     forum: number
  //     chats: number
  //     notifications: number
  //     mobile_drawer: number
  //     web_drawer: number
  //     deals: number
  //     events: number
  //     workshops: number
  //   }
  //   loading: {
  //     blocking: boolean
  //   }
  //   recent: {
  //     groups: number[]
  //   }
  filters: {
    categories: Record<string, boolean>
  }
  modalProps: {
    contract: ContractModalProps | undefined
    contactManager: ContactManagerModalProps | undefined
  }
  setters: {
    // redirectMobileScreen: (screen: string | undefined) => void
    // searchTerm: (term: string) => void
    modalProps: {
      contract: (props: ContractModalProps | undefined) => void
      contactManager: (props: ContactManagerModalProps | undefined) => void
    }

    filters: {
      categories: (categories: Record<string, boolean>) => void
    }
  }
  //   theme: { bg: "NORMAL" | "PROD_WARNING" }
}

const initialState: () => Omit<LocalSlice, "setters"> = () => ({
  modalProps: {
    contract: undefined,
    contactManager: undefined,
  },
  filters: {
    categories: {},
  },
})

export const createLocalSlice: StateCreator<StoreState, [], [], LocalSlice> = set => {
  resetActions.push(() => set(initialState()))
  return {
    ...initialState(),
    setters: {
      modalProps: {
        contract: (props: ContractModalProps | undefined) =>
          set(
            produce((state: StoreState) => {
              state.modalProps.contract = props
            })
          ),

        contactManager: (props: ContactManagerModalProps | undefined) =>
          set(
            produce((state: StoreState) => {
              state.modalProps.contactManager = props
            })
          ),
      },
      filters: {
        categories: (categories: Record<string, boolean>) =>
          set(
            produce((state: StoreState) => {
              state.filters.categories = categories
            })
          ),
      },
    },
  }
}
