import React, { useEffect } from "react"
import "../../../styles/commonStyles.css"
import "../../../styles/resetStyles.css"
import { resetTitle } from "../../utils/navigation"
import { useLocation } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import { Main } from "./Main"

export const App = (): JSX.Element => {
  // const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const location = useLocation()
  useEffect(() => resetTitle(), [location])
  return (
    <Main />
    // <LoginScreen />
  )
}
