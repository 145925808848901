import { forwardRef } from "react"
import styled from "styled-components"
import { withoutPropagation } from "../../utils/webUtils"
import { ButtonProps } from "../../../shared-client/props/ButtonProps"
import { TextStyled } from "./Text"
import {
  CONTENT_BACKGROUND,
  DT_GREEN,
  DT_GREEN_DARK,
  LA_RED,
  LA_RED_DARK,
  LA_RED_DISABLED,
  TEXT_DARK,
  TEXT_DARK_DIM,
  TEXT_LIGHT,
  TOUCH_HIGHLIGHT,
} from "../../../shared-client/constants/colors"
import { TextProps } from "../../../shared-client/props/TextProps"

const ButtonStyled = styled(TextStyled)<TextProps & { buttonType: "primary" | "secondary" }>`
  flex-shrink: 0;

  background-color: ${props => (props.buttonType === "primary" ? DT_GREEN : CONTENT_BACKGROUND)};
  color: ${props => (props.buttonType === "primary" ? TEXT_LIGHT : TEXT_DARK)};
  border-style: none;

  &:disabled {
    color: ${props => (props.buttonType === "primary" ? TEXT_LIGHT : TEXT_DARK_DIM)};
    background-color: ${props => (props.buttonType === "primary" ? LA_RED_DISABLED : CONTENT_BACKGROUND)};
  }
  &:hover:enabled,
  &:active:enabled {
    background-color: ${props => (props.buttonType === "primary" ? DT_GREEN_DARK : TOUCH_HIGHLIGHT)};
  }
`

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type, width, pad, title, bordRadius, onTap, ...rest }, ref) => {
    return (
      <ButtonStyled
        as={"button"}
        {...rest}
        ref={ref}
        pad={pad ?? ["half", "double"]}
        bordRadius={"half"}
        buttonType={type}
        onClick={withoutPropagation<HTMLButtonElement>(onTap)}
        bold
      >
        {title}
      </ButtonStyled>
    )
  }
)
