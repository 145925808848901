export const debounce = (fn: any, ms: number): (() => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null
  return function (this: any, ...args: any[]) {
    if (timeoutId == null) {
      fn.apply(this, args)
      timeoutId = setTimeout(() => {
        timeoutId = null
      }, ms)
    } else {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        timeoutId = null
        fn.apply(this, args)
      }, ms)
    }
  }
}

export const nullToUndefined = <T>(item: T | null): T | undefined => (item != null ? item : undefined)

export const debounceAsync = (
  fn: (...args: any[]) => Promise<void>,
  ms: number
): ((...args: any[]) => Promise<void>) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null
  return async function (this: any, ...args: any[]) {
    if (timeoutId == null) {
      timeoutId = setTimeout(() => {
        timeoutId = null
      }, ms)
      await fn.apply(this, args)
    } else {
      clearTimeout(timeoutId)
      await new Promise<void>(resolve => {
        timeoutId = setTimeout(resolve, ms)
        return timeoutId
      })
      await fn.apply(this, args)
    }
  }
}

export const NOT_FOUND = -1

const SPECIAL_CHARACTERS = " !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~" //TODO: Check if backend supports all these

const htmlBoldSplitRegex = new RegExp(["<b>", "</b>"].join("|"), "g")

export const splitTextOnEitherHtmlBoldTag = (text: string): string[] => text.split(htmlBoldSplitRegex)

export const dedupe = <T>(arr: T[]): T[] => [...new Set(arr)]

export const mapIds = (items: { _id: string }[]): string[] => items.map(item => item._id)

// export const mapIds = (items: { id: number }[]): number[] => items.map(item => item.id)

export const wrapId = (id: number): { id: number } => ({ id })
export const mapId = (item: { id: number }): number => item.id

export const isNotEmpty = <T>(item: T | undefined | null): item is T => item != null

export const isEmpty = <T>(item: T | undefined | null): item is null | undefined => item == null

export const isStringEmpty = (str: string | undefined | null): boolean => isEmpty(str) || str.length === 0

export const isStringNotEmpty = (str: string | undefined | null): str is string => !isStringEmpty(str)

export const isListEmpty = <T>(list: T[] | undefined | null): boolean => isEmpty(list) || list.length === 0

export const isListNotEmpty = <T>(list: T[] | undefined | null): list is T[] => !isListEmpty(list)

export const stringContainsSpecialCharacters = (str: string): boolean =>
  SPECIAL_CHARACTERS.split("").some(s => str.includes(s))

export const stringContainsUppercaseCharacters = (str: string): boolean => /[A-Z]/.test(str)

export const stringContainsLowercaseCharacters = (str: string): boolean => /[a-z]/.test(str)

export const stringContainsNumber = (str: string): boolean => /[0-9]/.test(str)

export const stringIsOnlyEmojis = (str: string): boolean => {
  if (!isNaN(+str)) return false
  const regex = /^(\p{Emoji}|\s)+$/u
  return regex.test(str)
}

export const hashCode = (str: string | null | undefined): number => {
  if (str == null) return 0
  let hash = 0
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const keys = (record: Record<number, unknown>): number[] => Object.keys(record).map(Number)
export const values = <T>(record: Record<number, T>): T[] => Object.values(record)
