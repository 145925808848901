import React, { FC } from "react"
import { Modal } from "../../../platform-impl/components/common/Modal"
import { ContractModalProps } from "../../props/ContractModalProps"
import { useGetContractModalProps, useSetContractModalProps } from "../../store/selectors"

export const ContractModal: FC = () => {
  const props = useGetContractModalProps()
  return props != null ? <ContractModalImpl {...props} /> : null
}

export const ContractModalImpl: FC<ContractModalProps> = ({ link }) => {
  const setContractModalProps = useSetContractModalProps()
  const close = (): void => setContractModalProps(undefined)
  return (
    <Modal width={1000} open={true} close={close} contentLabel={"Contract Listing"} title={"Contract Listing"}>
      <div className="h-96">
        <iframe className="w-full h-full" src={link} />
      </div>
    </Modal>
  )
}
