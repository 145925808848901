import React, { useEffect, useRef, useState } from "react"
import { NavIcon } from "../common/NavIcon"
import { OverflowMenu } from "./OverflowMenu"
import { Chat25, Clear15, Search25, Settings25 } from "../../IconPaths"
import styled from "styled-components"
import {
  HOME_PATH,
  useChatsNavigate,
  useHomeNavigate,
  useIsInChats,
  useIsInSearch,
  useIsInSettings,
  useNavigateToPath,
  useSearchNavigate,
  useSettingsNavigate,
} from "../../utils/navigation"
import { Icon } from "../common/Icon"
import {
  MAIN_MAX_WIDTH,
  MEDIUM_SCREEN_WIDTH,
  MIN_WIDTH,
  NAV_ICON_MIN_WIDTH,
  NAV_ICON_WIDTH,
  TOP_BAR_HEIGHT,
} from "../../utils/appConstants"
import { DT_GREEN, DT_GREEN_DARK, LA_RED, LA_RED_DARK } from "../../../shared-client/constants/colors"
import { DEFAULT_SPACING, SMALL_SPACING } from "../common/View"
import { Row } from "../common/Row"
import { Text } from "../common/Text"

const Background = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: ${MIN_WIDTH};
  height: ${TOP_BAR_HEIGHT}px;
  box-shadow: var(--drop-shadow);
  z-index: 2;
  padding: 1rem;
`

export const SideSpacer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 1;

  width: calc(3 * ${NAV_ICON_WIDTH}px + 2 * ${DEFAULT_SPACING}px);
  min-width: calc(3 * ${NAV_ICON_MIN_WIDTH}px + ${DEFAULT_SPACING}px);

  @media (max-width: 992px) {
    width: calc(2 * ${NAV_ICON_WIDTH}px + 2 * ${DEFAULT_SPACING}px);
    min-width: calc(2 * ${NAV_ICON_MIN_WIDTH}px + ${DEFAULT_SPACING}px);
  }
`

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 1;

  width: min(100%, ${MAIN_MAX_WIDTH});
  height: calc(${TOP_BAR_HEIGHT}px - 30px);

  margin: auto;
  background-color: #f0f0f0;
  border-radius: 10px;
  align-items: center;
`

const SearchInput = styled.input`
  display: flex;
  flex: 1;
  width: 0; /* needed to get the input to actually shrink */
  margin-left: ${DEFAULT_SPACING}px;
  margin-right: ${DEFAULT_SPACING}px;
  background-color: transparent;
  outline-width: 0;
`

const LaIcon = styled.img`
  width: ${TOP_BAR_HEIGHT}px;
  height: ${TOP_BAR_HEIGHT}px;
  padding: 10px;
  border-radius: 30px;
  transition: background-color 150ms;

  &:hover {
    background-color: var(--la-bg-hover);
  }
`

const DTTitle = styled.button`
  width: max-content;
  margin-left: ${SMALL_SPACING}px;
  margin-right: ${DEFAULT_SPACING}px;
  color: ${DT_GREEN};
  font-size: 28px;
  flex-shrink: 0;
  transition: color 150ms;
  align-self: center;
  font-weight: bold;

  border: none;
  background-color: inherit;
  padding: 0;

  &:hover {
    color: ${DT_GREEN_DARK};
  }

  @media only screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
    font-size: 16;
  }
`

const LaTitle = styled.button`
  --font-title: Palatino, "Times New Roman", serif;
  font-family: var(--font-title);
  width: max-content;
  margin-left: ${SMALL_SPACING}px;
  margin-right: ${DEFAULT_SPACING}px;
  color: ${LA_RED};
  font-size: x-large;
  flex-shrink: 0;
  transition: color 150ms;
  align-self: center;

  border: none;
  background-color: inherit;
  padding: 0;

  &:hover {
    color: ${LA_RED_DARK};
  }

  @media only screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
    display: none !important;
  }
`

export const TopBar = (): JSX.Element => {
  const searchInput = useRef<HTMLInputElement | null>(null)
  const isInSearch = useIsInSearch()
  // useEffect(() => {
  //   if (isInSearch) {
  //     searchInput.current?.focus()
  //   }
  // }, [searchInput, isInSearch])

  //   // void dispatch(Thunks.fetchStatus())
  // }, [dispatch])

  const homeNavigate = useNavigateToPath(HOME_PATH)
  const settingsNavigate = useSettingsNavigate()
  const chatsNavigate = useChatsNavigate()
  const searchNavigate = useSearchNavigate()

  // const onSearchChange = (term: string) => void dispatch(searchSlice.actions.setTerm(term))
  const backgroundAction = isInSearch ? () => searchInput.current?.focus() : searchNavigate

  const [searchTerm, setSearchTerm] = useState<string>("")

  return (
    <>
      <Background>
        <SideSpacer style={{ alignItems: "center" }}>
          <div style={{ flexShrink: 1, width: "20px" }} />
          {/* <LaTitle>Dihadi</LaTitle> */}
          <DTTitle onClick={homeNavigate}>Dihadi</DTTitle>
        </SideSpacer>

        <SearchBar>
          <div style={{ flexShrink: 1, width: "20px" }} />

          <Icon size={25} marg={[0, 0, 0, "def"]} paths={Search25} alt={"search"} activeColor={DT_GREEN} />
          <SearchInput
            ref={searchInput}
            placeholder={"Search by project name..."}
            onChange={event => setSearchTerm(event.target.value)}
            // disabled={!isInSearch}
            value={searchTerm}
            height={"28px"}
          />
          {searchTerm.length > 0 && (
            <Icon size={18} marg={[0, "def"]} paths={Clear15} onTap={() => setSearchTerm("")} alt={"clear icon"} tint />
          )}
        </SearchBar>

        <SideSpacer>
          {/* <NavIcon selected={useIsInChats()} iconPaths={Chat25} onClick={chatsNavigate} alt={"chats"} hideMedium />
          <NavIcon
            style={{ marginRight: "auto" }}
            selected={useIsInSettings()}
            iconPaths={Settings25}
            onClick={settingsNavigate}
            alt={"settings"}
            hideMedium
          /> */}
        </SideSpacer>
      </Background>
      <Row marg={["double", 0, 0, 0]}></Row>
    </>
  )
}
