import { Style } from "../../shared-client/props/BaseProps"

export const mergeStyles = (styles?: Style | Style[]): string => {
  if (styles === undefined) {
    return ""
  } else if (Array.isArray(styles)) {
    return styles.join(" ")
  } else {
    return styles as string
  }
}
