import React, { FC, useState } from "react"
import { Column } from "../../../platform-impl/components/common/Column"
import { Icon } from "../../../platform-impl/components/common/Icon"
import { Text } from "../../../platform-impl/components/common/Text"
import { DownCarrot, IconPaths, Metro25, UpCarrot } from "../../../platform-impl/IconPaths"
import { LA_RED_BG } from "../../constants/colors"

export interface TagProps {
  text: string
}

export const Tag: FC<TagProps> = ({ text }): JSX.Element => {
  // function backgroundAction(event: MouseEvent<HTMLDivElement, MouseEvent>): void {
  //   throw new Error("Function not implemented.")
  // }

  const [showDescription, setShowDescription] = useState(false)

  const dummyProjects = [
    {
      from: "Mumbai",
      to: "Hyderabad HSR",
    },
    {
      from: "Delhi",
      to: "Hyderabad HSR",
    },
    {
      from: "Pune",
      to: "Nasik HSR",
    },
    {
      from: "Nagpur",
      to: "Gangtok HSR",
    },
    {
      from: "Bro Nation",
      to: "Surat HSR",
    },
  ]

  return (
    <div className="p-1 bg-[#F2F6FF] mr-4 rounded-md shadow-md">
      <Text size={"13px"}>{text}</Text>
    </div>
  )
}
