import React, { FC, useState } from "react"
import { Text } from "../../../platform-impl/components/common/Text"
import { Tag } from "./Tag"
import { Button } from "../../../platform-impl/components/common/Button"
import { useGetContract, useSetContactManagerModalProps, useSetContractModalProps } from "../../store/selectors"

export interface Props {
  id: string
}

export const ContractTile: FC<Props> = ({ id }): JSX.Element => {
  const contract = useGetContract(id)
  const setContractModalProps = useSetContractModalProps()
  const setContactManagerModalProps = useSetContactManagerModalProps()
  return (
    <div className="w-full mx-auto my-4 md:w-5/12 shadow-custom  border flex-col rounded-md ">
      <div className="bg-[#B6FFF6] p-4 rounded-md ">
        <Text marg={["half", 0]} bold size={"24px"}>
          {contract?.subcategory}
        </Text>

        <Text marg={["half", 0]} size={"20px"}>
          Tata Projects
        </Text>

        <Text marg={["def", 0]} size={"12px"} maxLines={5}>
          {contract?.contract_description}
        </Text>
      </div>
      <div className="bg-white p-4 rounded-md ">
        <div className="flex justify-between ">
          <Text marg={["half", 0]} size={"16px"}>
            Contract Value
          </Text>

          <Text marg={["half", 0]} bold size={"18px"}>
            {`₹ ${contract?.contract_value} Cr`}
          </Text>
        </div>

        <div className="flex justify-between">
          <Text marg={["half", 0]} size={"16px"}>
            Available Work Items
          </Text>

          <Text marg={["half", 0]} bold size={"18px"}>
            {`${contract?.available_work_items}/${contract?.total_work_items}`}
          </Text>
        </div>

        <div className="my-4 flex">
          <Tag text="Metro" />
          <Tag text="10,000 Cr" />
          <Tag text="Tags" />
        </div>

        <div className="flex-col mt-8 ">
          <Button
            title="View Bill of quantities"
            type="primary"
            marg={["def", "auto"]}
            onTap={() => {
              setContractModalProps({ link: contract?.link_to_rates })
            }}
          />
          <Button
            title="Contact Owner"
            type="secondary"
            marg={["def", "auto"]}
            onTap={() => {
              console.log("He clicking this")
              setContactManagerModalProps({ contract_id: id })
            }}
          ></Button>
        </div>
      </div>
    </div>
  )
}
