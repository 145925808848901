import Cookies from "js-cookie"
import { StorageKey } from "../../shared-client/utils/storage"
import moment from "moment"

export const getPersistedString = (key: StorageKey): string | undefined => getValue(key)

export const getPersistedNumber = (key: StorageKey): number | undefined => {
  const value = parseInt(getValue(key))
  return isNaN(value) ? undefined : value
}

export const getPersistedBoolean = (key: StorageKey): boolean | undefined => {
  return getValue(key) === "true"
}

export const persistValue = ({ key, expiring }: StorageKey, value: any, expiresIn?: number): void => {
  if (expiring !== true && expiresIn != null) throw new Error("Cannot set expiresIn on non-expiring key")
  const expires = expiresIn != null ? moment().add(expiresIn, "seconds").toDate() : undefined
  Cookies.set(key, value, { expires })
}
export const clearKey = (key: StorageKey): void => persistValue(key, null)

const getValue = ({ key }: StorageKey): any => Cookies.get(key)
