import React, { FC } from "react"
import { Text } from "../../../platform-impl/components/common/Text"
import { Icon } from "../../../platform-impl/components/common/Icon"
import { Company25, Government25, IconPaths, Location25, Rupee25 } from "../../../platform-impl/IconPaths"
import { useFetchData } from "../../hooks/useFetchData"
import { fetchContracts } from "../../store/fetchActions"
import { useGetIdParam } from "../../../platform-impl/utils/navigation"
import { useGetContractIds, useGetProject } from "../../store/selectors"
import { ContractTile } from "../feed/ContractTile"
import { FullScreenLoading } from "../common/FullScreenLoading"

export const ProjectScreen = (): JSX.Element => {
  const projectId = useGetIdParam()

  const [error, tryAgain] = useFetchData(async () => fetchContracts(projectId), [projectId])
  const contractIds = useGetContractIds(projectId)
  const project = useGetProject(projectId)

  const feed = project
  if (feed != null) {
    return (
      <>
        <div className="mb-4 ml-2">
          <Text bold maxLines={2} size="30px">
            {project?.name}
          </Text>
        </div>

        <div className="w-full flex flex-wrap p-2 box-shadow rounded-4 shadow-lg">
          <div className=" w-full md:w-6/12 rounded-4 max-h-96 shadow-md">
            <img
              // src={ConstructionProjectFallImage.default.oneX}
              src={project?.photo_url}
              alt="Your Image"
              className="object-cover h-full w-full rounded-4 shadow-md"
            />
          </div>

          <div className="w-full md:w-6/12 md:px-3  flex flex-col ">
            <div className="w-full flex flex-wrap ">
              <ProjectInfoTile iconPath={Company25} value={project?.company_name ?? ""} />
              <ProjectInfoTile iconPath={Government25} value={"Government"} />
              <ProjectInfoTile iconPath={Location25} value={project?.location.city ?? ""} />
              <ProjectInfoTile iconPath={Rupee25} value={`${project?.total_value} Cr.`} />
            </div>
            <div className="p-3">
              <Text maxLines={6}>{project?.project_description}</Text>
            </div>
          </div>
        </div>

        <div className="flex-col my-3">
          {contractIds?.map(id => (
            <ContractTile key={id} id={id} />
          ))}
        </div>
      </>
    )
  }
  //  else if (error != null) {
  //   return <ErrorView errorText={"Could not load contracts"} tryAgain={tryAgain} />
  // }
  else {
    return <FullScreenLoading />
  }
}

interface ProjectInfoTileProps {
  iconPath: IconPaths
  value: string
}
const ProjectInfoTile: FC<ProjectInfoTileProps> = ({ iconPath, value }) => {
  return (
    <div className="w-6/12 p-2 ">
      <div className="bg-[#F6F9FF] py-3 px-2 flex items-center shadow-sm rounded-lg">
        <Icon paths={iconPath} alt={"haha"} size={45} marg={[0, "def", 0, 0]} />
        <Text maxLines={2} size={"16px"}>
          {value}
        </Text>
      </div>
    </div>
  )
}
